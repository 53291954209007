import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import Subscribe from "../elements/subscribe"
import CardTall from "../elements/card_tall"
import Heading from "../elements/heading"
import Modal from "../elements/text_modal"

const BarPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      modalImage4: file(relativePath: { eq: "modal4.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      modalImage5: file(relativePath: { eq: "modal5.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      modalImage6: file(relativePath: { eq: "modal6.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      # mocktail1: file(relativePath: { eq: "mocktail-front.jpg" }) {
      #   childImageSharp {
      #     fluid(maxWidth: 500) {
      #       ...GatsbyImageSharpFluid
      #     }
      #   }
      # }
      # mocktail2: file(relativePath: { eq: "mocktail-back.jpg" }) {
      #   childImageSharp {
      #     fluid(maxWidth: 500) {
      #       ...GatsbyImageSharpFluid
      #     }
      #   }
      # }
    }
  `)
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "bar" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
        description='At New Macau Casino, we stock an extensive range of alcoholic and non-alcoholic beverages to cater to our diverse clientele. We offer deals for our club card members! Terms and Conditions Apply.'
      />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "bar_title" })}
        sliderSubtitle={intl.formatMessage({ id: "bar_subtitle" })}
        objectPosition='80% 50%'
      />
      <Subscribe />
      <div className='hospitality__wrapper'>
        {/* <div className="hospitality">
          <div className="mb-m flex" >
            <Img fluid={data.mocktail1.childImageSharp.fluid} className="img"/>
            <Img fluid={data.mocktail2.childImageSharp.fluid} className="img" />
          </div>
        </div> */}
        <Heading
          title={intl.formatMessage({ id: "bar_heading" })}
          description={intl.formatMessage({ id: "bar_subheading" })}
        />

        <div className='hospitality'>
          <CardTall
            id='7'
            title={intl.formatMessage({ id: "bar_card1_title" })}
            subtitle={intl.formatMessage({ id: "bar_card1_subtitle" })}
            description={intl.formatMessage({ id: "bar_card1_description" })}
            content={
              <Modal
                title={intl.formatMessage({ id: "spirits_title" })}
                subtitle={intl.formatMessage({ id: "spirits_subtitle" })}
                description={intl.formatMessage({ id: "spirits_description" })}
                modalImage={data.modalImage4.childImageSharp.fixed}
              />
            }
          />
          <CardTall
            id='8'
            title={intl.formatMessage({ id: "bar_card2_title" })}
            subtitle={intl.formatMessage({ id: "bar_card2_subtitle" })}
            description={intl.formatMessage({ id: "bar_card2_description" })}
            content={
              <Modal
                title={intl.formatMessage({ id: "wine_title" })}
                subtitle={intl.formatMessage({ id: "wine_subtitle" })}
                description={intl.formatMessage({ id: "wine_description" })}
                modalImage={data.modalImage5.childImageSharp.fixed}
              />
            }
          />
          <CardTall
            id='9'
            title={intl.formatMessage({ id: "bar_card3_title" })}
            subtitle={intl.formatMessage({ id: "bar_card3_subtitle" })}
            description={intl.formatMessage({ id: "bar_card3_description" })}
            content={
              <Modal
                title={intl.formatMessage({ id: "cocktails_title" })}
                subtitle={intl.formatMessage({ id: "cocktails_subtitle" })}
                description={intl.formatMessage({
                  id: "cocktails_description",
                })}
                modalImage={data.modalImage6.childImageSharp.fixed}
              />
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(BarPage)
